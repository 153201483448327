import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Block = styled.div`
  width: 100%;
  height: 72px;
  position: relative;
  border-radius: 8px;

  :hover {
    box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
      0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  }

  @media (max-width: 1280px) {
    height: 48px;
  }

  input:-webkit-autofill::first-line {
    font-size: 16px;
    font-family: var(--typography-semibold);

    @media (max-width: 1280px) {
      font-size: 14px;
    }
  }

  input:-webkit-autofill ~ label {
    font-size: 12px;
    top: 15px;
    font-family: var(--typography-regular);

    @media (max-width: 1280px) {
      top: 5px;
    }
  }
`
type InputType = {
  valid: boolean
  maxLength?: number | string
}
type LabelType = {
  valid: boolean
}
const Label = styled.label<LabelType>`
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 26px;
  left: 24px;
  transition: top 0.2s ease, font-size 0.2s ease;
  font: var(--typography-16-20-semibold);

  @media (max-width: 1280px) {
    font-size: 14px;
    top: 14px;
    left: 16px;
  }

  color: ${props => (props.valid ? 'var(--color-sw-60)' : 'var(--color-red)')};
`

const Input = styled.input<InputType>`
  width: 100%;
  height: 72px;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #e2e2ea;
  border-radius: 8px;
  outline: none;
  padding: 15px 23px 0;
  color: ${props => (!props.valid ? 'var(--color-red)' : 'var(--color-night)')};
  font-size: 16px;
  font-family: var(--typography-semibold);
  :focus + ${Label}, :valid + ${Label} {
    font-size: 12px;
    top: 15px;
    font-family: var(--typography-regular);

    @media (max-width: 1280px) {
      top: 5px;
    }
  }
  :hover + ${Label} {
    color: var(--color-night);
  }

  transition: border 0.2s;

  @media (max-width: 1280px) {
    height: 48px;
    padding: 15px 15px 0;
    font-size: 14px;
  }

  /* prettier-ignore */
  :focus:not(:read-only) ~ ${Label},
  :not(:placeholder-shown):not([value='']) ~ ${Label} {
    color: var(--color-sw-60);
  }
`
const Error = styled.div`
  margin: 4px 0 4px 24px;
  min-height: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--color-red);

  @media (max-width: 1280px) {
    font-size: 12px;
    line-height: 12px;
    min-height: 12px;
    margin: 2px 0 2px 24px;
  }
`
interface IEmailProps {
  error: string
}
const Email = props => {
  const { error, ...rest }: IEmailProps = props

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Block>
        <Input
          type='email'
          id='email'
          required
          // @ts-ignore
          maxLength='50'
          spellCheck='false'
          valid={!error}
          {...rest}
        />
        <Label htmlFor='email' valid={!error}>
          {t('forgotPwd.email')}
        </Label>
      </Block>
      <Error>{error}</Error>
    </Wrapper>
  )
}

export default Email
